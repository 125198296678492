import React from 'react'

import {connect} from 'react-redux'

import Link from 'redux-first-router-link'

import {shakeHelper} from './utils.js'
import CheckIcon from '../svg/check.js'
import ArrowIcon from '../svg/arrow-right.js'

import {Screen} from './ui'

class ShakeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shakeHasOccured: false,
    }

    this.hasDeviceMotion = shakeHelper.hasDeviceMotion()
  }

  componentDidMount() {
    shakeHelper.start(() => this.setState({shakeHasOccured: true}))
  }

  componentWillUnmount() {
    shakeHelper.stop()
  }

  render() {
    const {shakeHasOccured} = this.state

    const success = (
      <Screen screenClass={`screen--blur screen--shake`}>
        <p className="text text--light">
          Super, das Schütteln klappt. Komm, wir gehen jetzt hinein und starten
          dann gleich in der Garderobe.
        </p>
        <Link className="icon-button" to={{type: 'POSITION_LIST'}}>
          <ArrowIcon />
        </Link>
      </Screen>
    )

    const shakeInfo = (
      <Screen screenClass={`screen--blur screen--shake`}>
        <p className="text text--light">
          Hallo, ich bin Allegra Vida.<br /> Toll, dass ich dich besuchen kann.
          Ich hoffe du bist einverstanden wenn wir uns duzen? Ich bin ja ganz
          neugierig, wie du lebst, darum werde ich dir viele Fragen stellen. Du
          kannst diese direkt hier mit dem Handy beantworten.
          {this.hasDeviceMotion
            ? 'Wenn du die Antwort nicht weisst, kannst du einfach dein Handy schütteln. Probier das bitte gleich mal aus:'
            : null}
        </p>
        {this.hasDeviceMotion ? (
          <img
            src={`${require('../img/shake.gif')}`}
            alt="Shaking Smartphone"
            className="icon icon--shake"
          />
        ) : null}
        <Link className="icon-button" to={{type: 'POSITION_LIST'}}>
          <CheckIcon />
        </Link>
      </Screen>
    )

    return shakeHasOccured ? success : shakeInfo
  }
}

export default connect(null, dispatch => ({
  onShake: () => dispatch({type: 'POSITION_LIST'}),
}))(ShakeView)
