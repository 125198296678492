import React from 'react'
import {connect} from 'react-redux'

import {Screen} from './ui'
import {positions} from './staticData'
import {selectPosition} from './actions'

class PositionList extends React.Component {
  constructor(props) {
    super(props)
  }

  get currentPositionKey() {
    /* find the first position that isn't completed */
    const currentPosition = positions.find(
      p => this.props.completedPositions.indexOf(p.postenId) < 0
    )

    return currentPosition ? currentPosition.postenId : null
  }

  getItemClassNames(key) {
    let classes = ['map__position']

    if (this.props.completedPositions.indexOf(key) > -1) {
      classes.push('map__position--disabled')
    }

    if (this.currentPositionKey === key) {
      classes.push('map__position--active')
    }

    return classes.join(' ')
  }

  onMapItemClick(key) {
    this.props.dispatch(selectPosition(key))
  }

  render() {
    return (
      <Screen screenClass={`screen--blur screen--map`}>
        <p className="text text--light">Wähle deinen nächsten Posten</p>

        <svg
          viewBox="0 0 271 337"
          xmlns="http://www.w3.org/2000/svg"
          className="map"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M136.062 202.126V67.376M32.61 135.627V67.375M238.39 202.126v-65.69M169.797 236.937h33.734M67.469 32.565h33.734"
              stroke="#FFF"
              strokeWidth="3.7"
              strokeLinecap="square"
            />
            <g
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P2')}
              className={this.getItemClassNames('P2')}
            >
              <path d="M33.195 0C14.895 0 .067 14.58.067 32.575c0 17.994 14.828 32.574 33.128 32.574s33.128-14.58 33.128-32.574C66.323 14.58 51.495 0 33.195 0zM17.983 27.66v1.412c0 1.081-.848 1.972-1.924 2.064l.446 14.418a1.026 1.026 0 0 1-1.038 1.052h-.622a1.025 1.025 0 0 1-1.038-1.052l.45-14.418c-1.077-.092-1.924-.983-1.924-2.064V27.66v-.016-.01-.02l.171-7.68a.305.305 0 0 1 .314-.295.305.305 0 0 1 .3.294l.172 7.68v.047h.608l-.001-.026v-.02l.172-7.68a.305.305 0 0 1 .313-.295.305.305 0 0 1 .3.294l.172 7.68-.001.047h.61l-.002-.026v-.02l.172-7.68a.305.305 0 0 1 .314-.295.305.305 0 0 1 .3.294l.172 7.68c0 .016 0 .031-.002.047h.608l.001-.016-.001-.01v-.02l.172-7.68a.305.305 0 0 1 .314-.295.305.305 0 0 1 .3.294l.172 7.68v.047zm15.8 18.18c-7.618 0-13.794-6.073-13.794-13.565 0-7.49 6.176-13.564 13.794-13.564 7.619 0 13.794 6.073 13.794 13.564 0 7.492-6.175 13.564-13.794 13.564zm20.274-11.755v11.923h-1.315c-.793 0-1.314-.33-1.314-1.457 0-.884.279-7.385.378-9.672H50.72a.568.568 0 0 1-.571-.561v-6.36c0-6.922 3.443-9.327 3.443-9.327.256-.18.465-.073.465.235v15.22z" />
              <path d="M33.783 44.662c-6.778 0-12.292-5.423-12.292-12.087 0-6.665 5.514-12.087 12.292-12.087s12.292 5.422 12.292 12.087c0 6.664-5.514 12.087-12.292 12.087zm0-22.89c-6.058 0-10.986 4.846-10.986 10.803 0 5.956 4.928 10.802 10.986 10.802 6.058 0 10.986-4.846 10.986-10.802 0-5.957-4.928-10.803-10.986-10.803z" />
              <rect
                pointerEvents="all"
                x="0"
                y="0"
                width="65"
                height="65"
                fill="none"
              />
            </g>
            <g
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P4')}
              className={this.getItemClassNames('P4')}
            >
              <path
                d="M135.501 203.295c-18.296 0-33.128 14.811-33.128 33.082 0 18.27 14.832 33.082 33.128 33.082s33.128-14.811 33.128-33.082c0-18.27-14.832-33.082-33.128-33.082zm-16.791 24.987c0-2.562.909-3.47 3.474-3.47h26.634c2.565 0 3.474.908 3.474 3.47v8.095H118.71v-8.095zm38.2 16.19h-1.144v3.47h-3.474v-3.47H118.71v3.47h-3.474v-3.47h-1.158a2.314 2.314 0 0 1-2.316-2.313v-9.251a2.314 2.314 0 0 1 2.316-2.313 2.314 2.314 0 0 1 2.316 2.313v5.782h38.214v-5.782a2.314 2.314 0 0 1 2.316-2.313 2.314 2.314 0 0 1 2.316 2.313v9.251a2.325 2.325 0 0 1-2.33 2.313z"
                fillRule="nonzero"
              />
              <rect
                pointerEvents="all"
                x="103"
                y="204"
                width="65"
                height="65"
                fill="none"
              />
            </g>
            <g
              transform="translate(0 136.997)"
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P1')}
              className={this.getItemClassNames('P1')}
            >
              <ellipse cx="42.379" cy="23.943" rx="3.094" ry="3.09" />
              <path d="M33.177.055C14.881.055.05 14.866.05 33.137c0 18.27 14.832 33.082 33.128 33.082s33.128-14.811 33.128-33.082c0-18.27-14.832-33.082-33.128-33.082zM47.82 32.96c-3.197 3.192-7.983 3.83-11.815 1.914l-2.943 2.939v4.314h-4.32v4.314h-4.32v4.314l-8.883-.01.01-4.574L31.432 30.31a10.212 10.212 0 0 1 1.917-11.8c3.996-3.99 10.474-3.99 14.47 0a10.207 10.207 0 0 1 0 14.45z" />
              <rect
                pointerEvents="all"
                x="0"
                y="0"
                width="65"
                height="65"
                fill="none"
              />
            </g>
            <g
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P5')}
              className={this.getItemClassNames('P5')}
            >
              <path
                d="M237.838 203.314c-18.296 0-33.128 14.812-33.128 33.083 0 18.27 14.832 33.082 33.128 33.082s33.128-14.811 33.128-33.082-14.832-33.083-33.128-33.083zm10.227 15.707a4.346 4.346 0 0 1 4.35 4.344 4.346 4.346 0 0 1-4.35 4.343 4.346 4.346 0 0 1-4.349-4.343 4.346 4.346 0 0 1 4.35-4.344zm-22.189.07c2.402 0 4.35 1.924 4.35 4.298s-1.948 4.298-4.35 4.298c-2.402 0-4.349-1.924-4.349-4.298s1.947-4.299 4.35-4.299zm7.27 21.955a1.5 1.5 0 0 1-1.936-.887l-.787-2.117s-.085-.308-.085.67v12.906a2.034 2.034 0 0 1-2.026 2.043 2.034 2.034 0 0 1-2.026-2.043v-8.431s.004-.075-.07-.075h-.761c-.079 0-.069.075-.069.075v8.43a2.034 2.034 0 0 1-2.026 2.044 2.034 2.034 0 0 1-2.025-2.043v-12.905c0-.964-.058-.743-.058-.743l-.814 2.189a1.5 1.5 0 0 1-1.937.887 1.521 1.521 0 0 1-.88-1.953l3.061-9.298c.258-.694.855-1.077 1.664-1.077h6.332c.21 0 .243-.009.597-.009.71 0 1.407.393 1.665 1.086l3.062 9.298a1.52 1.52 0 0 1-.88 1.953zm4.736 11.868c0 .41-.336.747-.747.747h-.32a.75.75 0 0 1-.748-.747v-32.693a.75.75 0 0 1 .747-.746h.32c.412 0 .748.336.748.746v32.693zm18.014-12.01c-.752.356-1.435.04-1.788-.72 0 0-.463-.986-.419-.708.155.97.736 4.529.736 4.529.127.693-.346 1.26-1.052 1.26h-1.806c-.129 0-.118.109-.118.109v6.652c0 1.118-.698 1.704-1.557 1.704-.86 0-1.557-.586-1.557-1.704v-6.69s.007-.07-.051-.07c-.103 0-.332.002-.422 0-.07-.003-.067.063-.067.063v6.697c0 1.118-.697 1.704-1.556 1.704-.86 0-1.557-.586-1.557-1.704v-6.67s.021-.09-.099-.09h-1.678c-.706 0-1.168-.566-1.027-1.256 0 0 .58-3.319.789-4.422.034-.177-.057-.017-.057-.017l-.29.594a1.5 1.5 0 0 1-2.014.694 1.521 1.521 0 0 1-.685-2.03l3.855-8.959c.249-.51.703-1.076 1.572-1.076h6.586c.872 0 1.06.61 1.272 1.066l3.711 9.027c.354.76.031 1.662-.721 2.018z"
                fillRule="nonzero"
              />
              <rect
                pointerEvents="all"
                x="205"
                y="204"
                width="65"
                height="65"
                fill="none"
              />
            </g>
            <g
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P6')}
              className={this.getItemClassNames('P6')}
            >
              <path d="M244.391 109.262H224.68a1.09 1.09 0 1 0 0 2.179h19.712a1.09 1.09 0 1 0 0-2.18zM237.772 113.62h-13.093a1.09 1.09 0 1 0 0 2.18h13.093a1.09 1.09 0 1 0 0-2.18zM224.679 107.082h8.728a1.09 1.09 0 0 0 1.091-1.09v-8.716a1.09 1.09 0 0 0-1.09-1.09h-8.73a1.09 1.09 0 0 0-1.09 1.09v8.717c0 .602.487 1.09 1.09 1.09zm1.09-8.716h6.547v6.537h-6.546v-6.537zM244.464 104.903h-6.62a1.09 1.09 0 1 0 0 2.18h6.62a1.09 1.09 0 1 0 0-2.18zM244.464 100.545h-6.62a1.09 1.09 0 1 0 0 2.18h6.62a1.09 1.09 0 1 0 0-2.18z" />
              <path d="M237.808 68.552c-18.296 0-33.128 14.812-33.128 33.083 0 18.27 14.832 33.082 33.128 33.082s33.128-14.812 33.128-33.082c0-18.271-14.832-33.083-33.128-33.083zm18.585 47.247c0 2.404-1.957 4.431-4.364 4.431h-27.35c-3.009 0-5.456-2.516-5.456-5.52V84.128c0-.602.488-1.09 1.091-1.09h28.442a1.09 1.09 0 0 1 1.09 1.09v3.342h5.456c.603 0 1.091.487 1.091 1.09v27.24z" />
              <path d="M224.679 94.007h19.712a1.09 1.09 0 0 0 1.091-1.09V88.56a1.09 1.09 0 0 0-1.09-1.09h-19.713a1.09 1.09 0 0 0-1.092 1.09v4.359c0 .602.488 1.09 1.092 1.09zm1.09-4.358H243.3v2.18h-17.53v-2.18zM249.92 115.8c0 1.2.979 2.251 2.182 2.251 1.203 0 2.182-1.05 2.182-2.252V89.65h-4.364v26.15zM244.464 96.186h-6.62a1.09 1.09 0 1 0 0 2.18h6.62a1.09 1.09 0 1 0 0-2.18z" />
              <rect
                pointerEvents="all"
                x="205"
                y="69"
                width="65"
                height="65"
                fill="none"
              />
            </g>
            <g
              fillRule="nonzero"
              onClick={() => this.onMapItemClick('P3')}
              className={this.getItemClassNames('P3')}
            >
              <path d="M129.683 39.302l-1.492 2.266h14.744l-1.492-2.266zM127.695 28.17h15.775c.964 0 1.753-.737 1.753-1.75V22c0-2.854-2.279-5.156-4.908-5.156h-9.553c-2.629 0-4.82 2.302-4.82 5.156l.088 4.42c0 .921.7 1.75 1.665 1.75zM128.981 37.376a2.835 2.835 0 0 0 2.835-2.831 2.835 2.835 0 0 0-5.67 0 2.836 2.836 0 0 0 2.835 2.83zM145.426 34.544a2.835 2.835 0 0 0-5.67 0 2.836 2.836 0 0 0 2.835 2.832c1.564 0 2.835-1.367 2.835-2.832zM126.76 44.917l-1.943 3.398h21.55l-1.943-3.398z" />
              <path d="M135.5 0c-18.32 0-33.172 14.831-33.172 33.126 0 18.295 14.852 33.126 33.172 33.126 18.32 0 33.172-14.83 33.172-33.126C168.672 14.831 153.82 0 135.5 0zm15.121 53.245c-.267.089-.534.178-.8.178-.623 0-1.157-.267-1.513-.889l-.533-.888h-24.55l-.534.888c-.266.533-.89.889-1.512.889-.267 0-.533 0-.8-.178-.89-.444-1.157-1.51-.712-2.398l5.87-10.837c-1.69-.266-2.934-1.687-2.934-3.464v-15.9c0-4.352 3.557-7.816 7.827-7.816h10.14c4.27 0 7.739 3.464 7.739 7.727v15.989c0 1.688-1.246 3.198-2.936 3.464l5.96 10.837c.445.888.178 1.954-.712 2.398z" />
              <rect
                pointerEvents="all"
                x="102"
                y="0"
                width="65.2"
                height="65.2"
                fill="none"
              />
            </g>
          </g>
        </svg>
      </Screen>
    )
  }
}

export default connect(state => ({
  questionList: state.questionList,
  completedQuestions: state.completedQuestions,
  completedPositions: state.completedPositions,
  currentPositionIndex: state.currentPositionIndex,
}))(PositionList)
