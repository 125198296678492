import React from 'react'

import {QuestionScreen} from './questionUi'

export default class SingleChoiceQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
    }
  }

  submit(answer) {
    if (!this.state.isSubmitting) {
      this.props.setAnswer(answer)
      this.setState({isSubmitting: true})
    }
  }

  render() {
    const {frage, antworten} = this.props

    const buttons = antworten.map((choice, i) => (
      <li className="button-list__item" key={i}>
        <button
          className={'button-list__button'}
          onClick={() => this.submit(choice.key)}
        >
          {choice.text}
        </button>
      </li>
    ))

    return (
      <QuestionScreen frage={frage} isSubmitting={this.state.isSubmitting}>
        <ul className={'button-list'}>{buttons}</ul>
      </QuestionScreen>
    )
  }
}
