import {createSelector} from 'reselect'
import {positions, staticQuestions} from './staticData'

export const currentPosition = createSelector(
  state => state.currentPositionIndex,
  () => positions,
  (positionIndex, positions) => positions[positionIndex]
)

export const currentQuestion = createSelector(
  currentPosition,
  state => state.currentQuestionIndex,
  state => state.questionList,
  () => staticQuestions,
  (currentPosition, currentQuestionIndex, questionList, staticQuestions) => {
    const key = currentPosition.questions[currentQuestionIndex]

    let question = questionList.find(q => q.key == key)

    if (!question) {
      question = staticQuestions.find(q => q.key == key)
    }
    return question
  }
)

export const nextQuestionUrl = createSelector(
  currentPosition,
  state => state.currentPositionIndex,
  state => state.currentQuestionIndex,
  (currentPosition, currentPositionIndex, currentQuestionIndex) => {
    if (currentQuestionIndex + 1 >= currentPosition.questions.length) {
      return '/position'
    } else {
      return `/position/${currentPositionIndex}/${currentQuestionIndex + 1}`
    }
  }
)
