import React from 'react'
import {connect} from 'react-redux'
import {SwipeDetector} from 'swipedetector'

import {Screen} from '../ui'
import {QuestionScreen} from './questionUi'
import {nextQuestion} from '../actions'

import IncorrectIcon from '../../svg/swiper-incorrect.js'
import CorrectIcon from '../../svg/swiper-correct.js'
import ArrowIcon from '../../svg/arrow-right.js'

const tutorialAnswers = [
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_1.jpg'),
  },
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_2.jpg'),
  },
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_3.jpg'),
  },
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_4.jpg'),
  },
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_5.jpg'),
  },
  {
    key: 'a',
    text: 'a',
    bild: require('../../img/swipe/tut_image_6.jpg'),
  },
]

class SwiperWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showTutorialSwiper: false,
    }
  }

  showTutorialSwiper = () => {
    this.setState({
      showTutorialSwiper: true,
    })
  }

  render() {
    const {completedTutorial, finishTutorial} = this.props
    const {showTutorialSwiper} = this.state

    // Default of completedTutorial is true. So the template never appears.
    return completedTutorial ? (
      <SwiperQuestion {...this.props} />
    ) : showTutorialSwiper ? (
      <SwiperTutorial
        frage={''}
        antworten={tutorialAnswers}
        finishTutorial={finishTutorial}
      />
    ) : (
      <SwiperInstruction showTutorialSwiper={this.showTutorialSwiper} />
    )
  }
}

export default connect(
  state => ({
    completedTutorial: state.completedSwipeTutorial,
  }),
  dispatch => ({
    nextQuestion: () => dispatch(nextQuestion()),
    finishTutorial: () => dispatch({type: 'SWIPE_TUTORIAL_COMPLETE'}),
  })
)(SwiperWrapper)

const SwiperInstruction = ({showTutorialSwiper}) => {
  return (
    <Screen screenClass={`screen--blur screen--swipe-instructions`}>
      <p className="text text--light">
        Swipe mit dem Finger die Bilder mit Motiven von Tieren auf die linke
        Seite und diejenigen mit Pflanzen auf die rechte Seite.
      </p>
      <img
        src={`${require('../../img/swipe/instruction.gif')}`}
        alt="Swiping"
        className="icon icon--swipe"
      />
      <button className="icon-button" onClick={showTutorialSwiper}>
        <ArrowIcon />
      </button>
    </Screen>
  )
}

class SwiperQuestion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      answers: [],
      currentSlide: 0,
      animation: null,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    this.events = new SwipeDetector(this.swiperEl).emitter

    this.events.addListener('left', this._swipeLeft)
    this.events.addListener('right', this._swipeRight)
  }

  componentWillUnmount() {
    this.events.removeListener('left', this._swipeLeft)
    this.events.removeListener('right', this._swipeRight)
  }

  _swipeLeft = () => this.answer(false)
  _swipeRight = () => this.answer(true)

  _onAnimationEnd = () => {
    const nextSlide = this.getNextSlide()
    let answers = this.state.answers

    const nextState = {
      currentSlide: nextSlide,
      animation: null,
    }

    if (this.state.animation === 'right') {
      nextState.answers = answers = [
        ...answers,
        this.props.antworten[this.state.currentSlide].key,
      ]
    }

    if (!nextSlide) {
      this.submitAnswers(answers)
    }

    this.setState(nextState)
  }

  answer(yay) {
    if (!this.state.animation) {
      this.setState({
        animation: yay ? 'right' : 'left',
      })
    }
  }

  getNextSlide() {
    let nextSlide = this.state.currentSlide + 1

    if (nextSlide <= this.props.antworten.length - 1) {
      return nextSlide
    } else {
      return null
    }
  }

  submitAnswers(answers) {
    if (!this.state.isSubmitting) {
      answers.length ? this.props.setAnswer(answers) : this.props.nextQuestion()
      this.setState({isSubmitting: true})
    }
  }

  render() {
    const currentSlide = this.props.antworten[this.state.currentSlide]
    const {animation, isSubmitting} = this.state
    const feedbackIcon =
      animation === 'right' ? <CorrectIcon /> : <IncorrectIcon />

    return (
      <QuestionScreen frage={this.props.frage} isSubmitting={isSubmitting}>
        {
          //this.state.currentSlide !== null ? (
          <div
            className={`swiper ${
              animation !== null ? 'animation-' + animation : ''
            }`}
            ref={swiper => (this.swiperEl = swiper)}
          >
            <div
              className={`swiper__slide ${
                !currentSlide ? 'swiper__slide--empty' : ''
              }`}
              style={
                currentSlide
                  ? {backgroundImage: 'url(' + currentSlide.bild + ')'}
                  : null
              }
            />
            {animation ? (
              <div
                className="swiper__background"
                onAnimationEnd={() => this._onAnimationEnd()}
              >
                {feedbackIcon}
              </div>
            ) : null}
          </div>
          /*)  : null*/
        }

        <div className="swiper-button-group">
          <button className="icon-button" onClick={() => this.answer(false)}>
            <IncorrectIcon />
          </button>
          <button className="icon-button" onClick={() => this.answer(true)}>
            <CorrectIcon />
          </button>
        </div>
      </QuestionScreen>
    )
  }
}

class SwiperTutorial extends SwiperQuestion {
  submitAnswers() {
    this.props.finishTutorial()
  }
}
