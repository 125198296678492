import React from 'react'

import {QuestionScreen} from './questionUi'

const FourOFour = ({frage}) => {
  return (
    <QuestionScreen frage={frage}>
      <button className={`button`} onClick={() => window.location.href = '/'}>
        Zurück zum Start
      </button>
    </QuestionScreen>
  )
}

export default FourOFour
