import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.93 34.23L28.99 49.963c-1.56 1.541-4.229.45-4.229-1.729V16.766c0-2.178 2.668-3.27 4.23-1.73L44.928 30.77a2.425 2.425 0 0 1 0 3.46M0 32.5C0 50.453 14.546 65 32.5 65 50.453 65 65 50.453 65 32.5S50.453 0 32.5 0C14.546 0 0 14.547 0 32.5z"
        fill="#CAFF62"
        fillRule="evenodd"
        opacity=".9"
      />
    </svg>
  )
}
