import React from 'react'
import ReactDOM from 'react-dom'
import {Provider, connect} from 'react-redux'

import Messages from './messages'
import {views} from './routes'
import store from './store'
import {messageAction} from './actions'

const App = connect(state => ({
  locationType: state.location.type,
}))(({locationType, dispatch}) => {
  if (views[locationType]) {
    return React.createElement(views[locationType])
  }
  dispatch(
    messageAction('Unknown address', {
      meta: {
        buttonTitle: 'Go to the start',
        url: '/',
      },
    })
  )
  return null
})

export function renderInElement(element) {
  ReactDOM.render(
    <Provider store={store}>
      <div className="content">
        <App />
        <Messages />
      </div>
    </Provider>,
    element
  )
}
