import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 0C31.825 0 41 9.175 41 20.5S31.825 41 20.5 41 0 31.825 0 20.5 9.175 0 20.5 0zm9.415 19.095l-11.2-11.2a1.976 1.976 0 0 0-2.803 0l-1.405 1.404a1.976 1.976 0 0 0 0 2.803l8.398 8.398-8.398 8.398a1.976 1.976 0 0 0 0 2.803l1.405 1.405a1.976 1.976 0 0 0 2.803 0l11.2-11.2a1.986 1.986 0 0 0 0-2.811z"
        fill="#CAFF62"
        fillRule="nonzero"
        opacity=".9"
      />
    </svg>
  )
}
