import React from 'react'
import {connect} from 'react-redux'

import loadImage from 'blueimp-load-image'

import {skipPhotoUpload} from '../actions'

import {QuestionScreen, QuestionErrorScreen} from './questionUi'
import CheckIcon from '../../svg/check.js'
import PhotoIcon from '../../svg/photo.js'

class PhotoUploadQuestion extends React.Component {
  constructor(props) {
    super(props)

    this.image = null
    this.loadingImage = null

    this.state = {
      hasImage: false,
      isSubmitting: false,
      loading: false,
      hasError: false,
    }
  }

  componentWillUnmount() {
    this.image = null

    if (this.loadingImage) {
      // abort the loading process
      this.loadingImage.onload = this.loadingImage.onerror = null
    }
  }

  onSubmit() {
    if (!this.state.isSubmitting) {
      this.props.setAnswer(this.image)
      this.setState({isSubmitting: true})
    }
  }

  onChange() {
    try {
      this.previewFile()
    } catch (err) {
      window.console.error(err)

      this.setState({
        hasError: true,
      })
    }
  }

  previewFile() {
    let file = this.inputEl.files[0]

    if (file) {
      this.setState({
        loading: true,
      })

      this.loadingImage = loadImage(
        this.inputEl.files[0],
        canvas => {
          if (canvas.type === 'error') {
            this.setState({
              hasError: true,
              hasImage: false,
              loading: false,
            })
          } else {
            this.image = canvas.toDataURL()
            this.setState({
              hasImage: true,
              loading: false,
            })
          }
        },
        {
          // handle EXIF orientation
          orientation: true,
          maxWidth: 1024,
          maxHeight: 1024,
        }
      )
    } else {
      this.image = null

      this.setState({
        hasImage: false,
      })
    }
  }

  render() {
    const {frage} = this.props
    const {hasImage, isSubmitting, loading, hasError} = this.state

    if (hasError) {
      return (
        <QuestionErrorScreen />
      )
    }

    return (
      <QuestionScreen frage={frage} isSubmitting={isSubmitting} questionType={'photo-upload'}>
        <div
          className={`photo-upload ${hasImage ? 'photo-upload--preview' : ''}`}
        >
          <div
            className="photo-upload__preview"
            style={hasImage ? {backgroundImage: `url(${this.image})`} : null}
          >
            {loading ? <div className="loading-indicator" /> : null}
          </div>

          <div className="photo-upload__buttons">
            <label className="photo-upload__label icon-button">
              <input
                type="file"
                className="photo-upload__input"
                ref={input => (this.inputEl = input)}
                onChange={e => this.onChange(e)}
                accept="image/*"
              />
              <PhotoIcon />
            </label>
            {hasImage ? (
              <button className={`icon-button`} onClick={() => this.onSubmit()}>
                <CheckIcon />
              </button>
            ) : null}
          </div>
          <a
            href="#"
            className="photo-upload__skip"
            onClick={e => {e.preventDefault(); this.props.skip()}}
          >
            Überspringen
          </a>
        </div>
      </QuestionScreen>
    )
  }
}

export default connect(null, dispatch => ({
  skip: () => {
    dispatch(skipPhotoUpload())
  },
}))(PhotoUploadQuestion)
