import 'isomorphic-fetch'
import Shake from 'shake.js'

export function checkResponse(response) {
  if (response.ok) return response
  if (response.status === 401) window.location.reload()
  throw Error(response.statusText)
}

export async function jsonFetch(url, options) {
  return await checkResponse(await fetch(url, options)).json()
}

export const shakeHelper = {
  start: function(callback) {
    this.shakeEvent = new Shake({
      threshold: 15, // optional shake strength threshold
      timeout: 1000, // optional, determines the frequency of event generation
    })

    this.callback = callback

    this.shakeEvent.start()
    window.addEventListener('shake', this.callback)
  },

  stop: function() {
    window.removeEventListener('shake', this.callback)
    this.shakeEvent.stop()
  },

  hasDeviceMotion: function() {
    return 'ondevicemotion' in window
  },
}
