import React from 'react'

import {QuestionScreen} from './questionUi'
import ArrowIcon from '../../svg/arrow-right.js'

export default class TextInputQuestion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      isValid: false,
      isSubmitting: false,
    }
  }

  onInput(e) {
    this.setState({
      isValid: this.validate(),
      text: e.target.value,
    })
  }

  onKeyUp(e) {
    if (e.keyCode === 13 && this.state.isValid) {
      this.props.setAnswer(this.inputEl.value)
    }
  }

  onSubmit() {
    if (this.state.isValid) {
      this.props.setAnswer(this.inputEl.value)
    }
  }

  submit() {
    if (!this.state.isSubmitting) {
      this.props.setAnswer(this.inputEl.value)
      this.setState({isSubmitting: true})
    }
  }

  validate = () => this.inputEl.validity.valid

  render() {
    const {frage, datentyp, settings} = this.props
    const {isSubmitting} = this.state

    return (
      <QuestionScreen frage={frage} isSubmitting={isSubmitting}>
        {settings.prefix !== '' ? <p>{settings.prefix}</p> : null}
        <input
          type={datentyp === 'int' ? 'number' : 'text'}
          value={this.state.text}
          ref={input => (this.inputEl = input)}
          onInput={e => this.onInput(e)}
          onKeyUp={e => this.onKeyUp(e)}
          required="required"
        />
        {settings.postfix !== '' ? <p>{settings.postfix}</p> : null}
        <button
          className={`icon-button ${
            !this.state.isValid ? 'icon-button--disabled' : ''
          }`}
          onClick={() => this.onSubmit()}
        >
          <ArrowIcon />
        </button>
      </QuestionScreen>
    )
  }
}
