import {jsonFetch} from './utils'
import {positions} from './staticData'
import {currentPosition} from './selectors'

const API = {
  host: 'https://api.ausstellung-mislaebe.ch/api',
  key: 'WZmNmJlYTEtZGU5MC00M2IxLWJlMDUtOTlkNGZhNzAwZWFj',
  endpoints: {
    questions: '/fragen',
    sessions: '/sessions',
    answers: '/antworten',
  },
}

function getApiEndpoint(endpoint) {
  return API.host + API.endpoints[endpoint] + '/?api_key=' + API.key
}

// Used as thunk of the start view
export async function fetchInitialData(dispatch, getState) {
  const {questionList, session} = getState()

  // Why should the action know the state key of its data?
  if (questionList.length && session.session_id) return

  // fetch questions
  try {
    const data = await jsonFetch(
      getApiEndpoint('questions'),
      // '/data/fragen.json',
      {
        credentials: 'same-origin',
      }
    )
    dispatch({
      type: 'QUESTION_LIST_LOADED',
      questionList: data,
    })
  } catch (err) {
    dispatch(errorMessageAction())
    // dispatch({type: 'QUESTION_LIST_FAILED', error: err})
  }

  // create a new session
  try {
    const sessionData = await jsonFetch(getApiEndpoint('sessions'), {
      method: 'POST',
      credentials: 'same-origin',
    })
    dispatch({
      type: 'SESSION_CREATED',
      session: sessionData,
    })
  } catch (err) {
    dispatch(errorMessageAction())
    // dispatch({type: 'SESSION_FAILED', error: err})
  }
}

export function submitAnswer(question, answer) {
  return async (dispatch, getState) => {
    let currentPathname = getState().location.pathname

    dispatch({
      type: 'ANSWER_SUBMISSION_STARTED',
      question,
      answer,
    })

    try {
      const data = await jsonFetch(getApiEndpoint('answers'), {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(answer),
        headers: {'content-type': 'application/json'},
      })

      dispatch({
        type: 'ANSWER_SUBMISSION_SUCCEEDED',
        question,
        answer,
        response: data,
      })

      // don't navigate to the nextQuestion if the location changed between
      // the answer submission and the actual end of the fetch request.
      if (currentPathname === getState().location.pathname) {
        dispatch(nextQuestion())
      }
    } catch (err) {
      if (currentPathname === getState().location.pathname) {
        dispatch(errorMessageAction())
      }
      dispatch({
        type: 'ANSWER_SUBMISSION_FAILED',
        question,
        answer,
      })
    }
  }
}

export function silentlySendAnswer(answer) {
  jsonFetch(getApiEndpoint('answers'), {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(answer),
    headers: {'content-type': 'application/json'},
  }).catch(error => {
    // Silently fail, doesn't matter
    window.console.error(error)
  })
}

export function dismissMessageAction(messageId) {
  return {
    type: 'DISMISS_MESSAGE',
    messageId,
  }
}

export function errorMessageAction() {
  return messageAction(
    'Es ist ein Fehler aufgetreten. Lade die Seite neu und versuche es noch einmal.',
    {
      meta: {
        reload: true,
      },
    }
  )
}

export function messageAction(msg, attrs) {
  let message = {
    id: Math.random(),
    message: msg,
    tags: '',
    level_tag: 'info',
    meta: {},
  }
  return {
    type: 'MESSAGE',
    __messages__: [{...message, ...attrs}],
  }
}

export function nextQuestion() {
  return (dispatch, getState) => {
    const state = getState()

    if (
      state.currentQuestionIndex + 1 >=
      currentPosition(state).questions.length
    ) {
      dispatch(completePosition())
    } else {
      dispatch({
        type: 'QUESTION_DETAILS',
        payload: {
          questionIndex: state.currentQuestionIndex + 1,
          positionIndex: state.currentPositionIndex,
        },
      })
    }
  }
}

// The "screen" after the photo upload is a success message. This should be skipped too
// This is a very dirty hack. Sorry for that :(
export function skipPhotoUpload() {
  return (dispatch, getState) => {
    const state = getState()

    if (
      state.currentQuestionIndex + 2 >=
      currentPosition(state).questions.length
    ) {
      dispatch(completePosition())
    } else {
      dispatch({
        type: 'QUESTION_DETAILS',
        payload: {
          questionIndex: state.currentQuestionIndex + 2,
          positionIndex: state.currentPositionIndex,
        },
      })
    }
  }
}

export function selectPosition(key) {
  return (dispatch, _state) => {
    let selectedPosition = positions.find(p => p.postenId === key)
    let selectedPositionIndex = positions.indexOf(selectedPosition)

    dispatch({
      type: 'POSITION_DETAILS',
      payload: {id: selectedPositionIndex},
    })
  }
}

export function completePosition() {
  return (dispatch, getState) => {
    const state = getState()
    const {completedPositions} = state
    const position = currentPosition(state)

    if (completedPositions.length + 1 === positions.length) {
      dispatch({
        type: 'COMPLETE_POSITION',
        position,
      })

      dispatch({
        type: 'COMPLETE_COURSE',
      })
    } else {
      dispatch({
        type: 'COMPLETE_POSITION',
        position,
      })
      dispatch({type: 'POSITION_LIST'})
    }
  }
}
