import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="#CAFF62" opacity=".9">
        <path d="M47.978 22.69h-8.895l-.97-2.162c-.222-.486-.534-.647-.779-.647h-9.596c-.242 0-.56.162-.78.647l-.97 2.161h-8.894c-.79 0-1.404.614-1.404 1.404v19.654c0 .79.613 1.404 1.404 1.404h30.884c.79 0 1.404-.614 1.404-1.404V24.093c0-.79-.614-1.404-1.404-1.404zm-26.673 5.614h-2.807v-2.807h2.807v2.807zm11.231 14.039c-4.635 0-8.423-3.788-8.423-8.423 0-4.636 3.788-8.423 8.423-8.423s8.423 3.787 8.423 8.423c0 4.635-3.788 8.423-8.423 8.423z" />
        <path d="M32.536.06C14.606.06.08 14.586.08 32.516c0 17.93 14.526 32.456 32.456 32.456 17.93 0 32.456-14.526 32.456-32.456C64.992 14.586 50.465.06 32.536.06zM52.19 43.747c0 2.309-1.902 4.211-4.212 4.211H17.094c-2.31 0-4.212-1.902-4.212-4.211V24.093c0-2.31 1.902-4.212 4.212-4.212h7.08l.224-.504c.596-1.328 1.863-2.303 3.34-2.303h9.596c1.478 0 2.742.975 3.34 2.297v.006l.23.504h7.074c2.31 0 4.212 1.903 4.212 4.212v19.654z" />
        <path d="M32.536 28.304a5.594 5.594 0 0 0-5.615 5.616 5.594 5.594 0 0 0 5.615 5.615 5.594 5.594 0 0 0 5.615-5.615 5.594 5.594 0 0 0-5.615-5.616z" />
      </g>
    </svg>
  )
}
