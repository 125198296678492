import React from 'react'

import {Screen} from './ui'

export function restartApp() {
  window.localStorage.removeItem('redux')
  window.location.href = '/'
}

export const Restart = () => {
  restartApp()

  return (
    <Screen screenClass={`screen--start`} screenTitle="Start">
      <div className="loading-indicator" />
    </Screen>
  )
}
