export const positions = [
  {
    postenId: 'P1',
    postenName: 'Garderobe',
    questions: [
      // 'G01',
      'G02',
      'G03',
      'G04',
      'G05',
      'G06',
      'G07',
      'G08',
      'G09',
      'G10',
      'K01',
    ],
  },

  {
    postenId: 'P2',
    postenName: 'Küche',
    questions: [
      // 'K02',
      // 'K03',
      'K04',
      'K05',
      // 'K06',
      // 'K07',
      'K08',
      'K09',
      'K10',
      'K11',
      'K12',
      'K13',
      'K14',
      'K15',
      // 'K16',
      'K17',
      'K18',
      'M01',
    ],
  },

  {
    postenId: 'P3',
    postenName: 'Mobilität',
    questions: [
      // 'M02',
      // 'M03',
      'M04',
      'M05',
      'M06',
      'M07',
      'M08',
      // 'M09',
      'M10',
      'M11',
      'W01',
      // 'W02',
      // 'W03',
      // 'W04',
      'W05',
      'W06',
      'W07',
      'W08',
      // 'W09',
      'W10',
      'W11',
      'W12',
    ],
  },

  {
    postenId: 'P4',
    postenName: 'Wohnzimmer',
    questions: [
      'W13',
      'W14',
      'W15',
      'W15b',
      // 'W16',
      'W19',
      'W20',
      'S01',
    ],
  },

  {
    postenId: 'P5',
    postenName: 'WC-Häuschen',
    questions: [
      // 'S02',
      // 'S03',
      'S04',
      'S05',
      'S06',
      'S07',
      'S08',
      'S09',
      'S10',
      'S11',
      'S12',
      'S13',
      'S14',
      'S15',
      'S16',
      'S17',
      'S18',
      'S19',
      'S20',
      'S21',
      'S22',
      'S23',
      'S24',
      // 'S25',
      // 'S26',
      // 'S27',
      'B01',
    ],
  },

  {
    postenId: 'P6',
    postenName: 'Büro',
    questions: [
      // 'B02',
      // 'B03',
      // 'B04',
      'B05',
      'B05b',
      'B06',
      'B07',
      'B08',
      'B09',
      'B10',
      'B11',
      'B12',
      'B13',
      'B14',
      'B15',
      'B16',
      'B17',
      'B18',
      'B19',
      'B20',
      'B21',
      'B22',
      'B23',
      'B24',
    ],
  },
]

export const staticQuestions = [
  /*{
    feature_name: 'F0',
    key: 'G01',
    frage: {text: 'Zahl 1 zur Auswahl auf dem Screen'},
  },*/
  {
    feature_name: 'F0',
    key: 'G02',
    frage: {
      text:
        'Fantastisch, dass ich mich bei dir umschauen darf! Kann ich hier meine Jacke aufhängen?',
    },
  },
  {
    feature_name: 'F0',
    key: 'G03',
    frage: {
      text:
        'Halte dein Handy über die beiden Symbole unter der Garderobe. Erkennst du, was sich dahinter verbirgt?',
    },
  },
  {
    feature_name: 'F0',
    key: 'K01',
    frage: {
      text:
        'Wir stehen ja immer noch in der Garderobe! Lass uns weiter in die Küche gehen.',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'K02',
    frage: {
      text: 'Übersicht mit allen Posten, Auswahl Posten 2 / Küche machen',
    },
  },*/
  /*{
    feature_name: 'F0',
    key: 'K03',
    frage: {text: 'Zahl 2 / Küche zur Auswahl auf dem Screen'},
  },*/
  {
    feature_name: 'F0',
    key: 'K04',
    frage: {
      text:
        'Könnte ich bitte gleich einen Kaffee haben? Ohne Kaffee geht bei mir nämlich gar nichts, ich bin so ein Kaffee-Junkie! Ich trinke mindestens 5 Tassen pro Tag.',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'K07',
    frage: {
      text:
        'Und dann möchte ich natürlich wissen, was du gerne isst! Dazu testen wir jetzt das Swipen.',
    },
  },*/
  {
    feature_name: 'F0',
    key: 'K09',
    frage: {
      text:
        'Mhmm, bei diesen Menüs bekomme ich gleich Hunger. Darf ich einen Blick in deinen Kühlschrank werfen? Bitte öffne ihn!',
    },
  },
  {
    feature_name: 'F0',
    key: 'K18',
    frage: {
      text:
        'Das hat geklappt. Du siehst das Bild am Ende in deiner Homestory wieder.',
    },
  },
  {
    feature_name: 'F0',
    key: 'M01',
    frage: {
      text:
        'Lass uns weitergehen. Wow, dieser SBB-Sitz ist ja ein spezielles Möbelstück! Sitzt man hier bequem? Das muss ich gleich ausprobieren.',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'M02',
    frage: {
      text: 'Übersicht mit allen Posten, Auswahl Posten 3 / Mobilität machen',
    },
  },*/
  /*{
    feature_name: 'F0',
    key: 'M03',
    frage: {text: 'Zahl 3 / Mobilität zur Auswahl auf dem Screen'},
  },*/
  {
    feature_name: 'F0',
    key: 'M04',
    frage: {
      text:
        'Ah interessant, du hast ja hier eine richtige Hör-Bar eingerichtet. Komm, wir probieren diese Kopfhörer aus.',
    },
  },
  {
    feature_name: 'F0',
    key: 'M11',
    frage: {
      text:
        'Das hat geklappt. Du siehst das Bild am Ende in deiner Homestory wieder.',
    },
  },
  {
    feature_name: 'F0',
    key: 'W01',
    frage: {
      text:
        'Komm, gehen wir weiter. Woher hast du denn diese Weltkarte? So eine habe ich ja noch nie gesehen. Die macht grad Lust auf Ferien!',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'W02',
    frage: {
      text: 'Übersicht mit allen Posten, Auswahl Posten 4 / Wohnzimmer machen',
    },
  },*/
  /*{
    feature_name: 'F0',
    key: 'W03',
    frage: {text: 'Zahl 4 / Wohnzimmer zur Auswahl auf dem Screen'},
  },*/
  {
    feature_name: 'F0',
    key: 'W10',
    frage: {
      text:
        'Dreh dich mal um. Komm wir schauen uns noch die Ferienfotos hier in den Bilderrahmen an der Wand an!',
    },
  },
  {
    feature_name: 'F0',
    key: 'W12',
    frage: {
      text:
        "Das ist aber gemütlich bei dir. Darf ich mich gleich auf's Sofa setzen?",
    },
  },
  {
    feature_name: 'F0',
    key: 'W14',
    frage: {
      text:
        'Cool, dank dem Buch hier auf dem Tischchen hat man den direkten Blick in den Keller!',
    },
  },
  {
    feature_name: 'F0',
    key: 'W18',
    frage: {text: 'Komm, wir machen noch ein Foto mit dir hier im Wohnzimmer.'},
  },
  {
    feature_name: 'F0',
    key: 'W20',
    frage: {
      text:
        'Das hat geklappt. Du siehst das Bild am Ende in deiner Homestory wieder.',
    },
  },
  {
    feature_name: 'F0',
    key: 'S01',
    frage: {
      text:
        'So, nun sind wir fast durch. Bevor ich gehe – kann ich noch schnell die Toilette benutzen?',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'S02',
    frage: {text: 'Übersicht mit allen Posten, Auswahl Posten 5 / WC machen'},
  },*/
  /*{
    feature_name: 'F0',
    key: 'S03',
    frage: {text: 'Zahl 5 / WC zur Auswahl auf dem Screen'},
  },*/
  {
    feature_name: 'F0',
    key: 'S04',
    frage: {
      text:
        'Zum Schluss habe ich noch ein paar persönlichere Fragen. Beantworte diese in Ruhe auf dem WC. Ich warte so lange auf dem Sofa.',
    },
  },
  {
    feature_name: 'F0',
    key: 'S06',
    frage: {
      text:
        'Nimm dir nun bitte etwas Zeit, um die folgenden Fragen zu beantworten. Damit erfährst du dann am Schluss von der Reporterin, was dir wirklich wichtig ist im Leben.',
    },
  },
  {
    feature_name: 'F0',
    key: 'B01',
    frage: {
      text:
        'Cool, du hast es geschafft! Das war ja wirklich mega spannend bei dir! Komm wir gehen gerade weiter in mein Büro, da zeige ich dir deine Homestory! Mit all deinen Antworten kann ich dir sogar noch ein paar Tipps auf den Weg geben.',
    },
  },
  /*{
    feature_name: 'F0',
    key: 'B02',
    frage: {
      text:
        'Übersicht mit allen Posten, Auswahl Posten 6 / Fertige Homestory machen',
    },
  },*/
  /*{
    feature_name: 'F0',
    key: 'B03',
    frage: {text: 'Zahl 6 / Fertige Homestory zur Auswahl auf dem Screen'},
  },*/
  /*{
    feature_name: 'F0',
    key: 'B04',
    frage: {
      text:
        "Nun brauche ich nur noch ein Foto von dir für die Titelseite. Wäre das hier nicht ein guter Hintergrund? Mach' bitte noch ein letztes Selfie!",
    },
  },*/
  {
    feature_name: 'F0',
    key: 'B05b',
    frage: {
      text:
        'Das hat geklappt. Du siehst das Selfie gleich als Titelbild in deiner Homestory.',
    },
  },
  {
    feature_name: 'FHS',
    key: 'B06',
    frage: {
      text:
        'Gratulation! Mit den Angaben aus deinem Leben haben wir deine persönliche Homestory erstellt. Und sogar ein paar persönliche Tipps haben wir für dich gesammelt. Viel Spass beim Lesen und Teilen und vielen Dank, dass du bei #misläbe dabei warst!',
    },
  },
]
