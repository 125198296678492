import React from 'react'

import {QuestionScreen} from './questionUi'
import CheckIcon from '../../svg/check.js'

export default class ImageGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAnswer: null,
      isSubmitting: false,
    }
  }

  select(key) {
    if (this.state.selectedAnswer === key) {
      this.setState({selectedAnswer: null})
    } else {
      this.setState({selectedAnswer: key})
    }
  }

  submit() {
    if (!this.state.isSubmitting) {
      this.props.setAnswer(this.state.selectedAnswer)
      this.setState({isSubmitting: true})
    }
  }

  render() {
    const {frage, antworten} = this.props
    const {selectedAnswer, isSubmitting} = this.state

    const buttons = antworten.map((choice, i) => (
      <li className="image-grid__item" key={i}>
        <button
          className={`image-grid__button ${
            selectedAnswer == choice.key ? 'image-grid__button--selected' : ''
          }`}
          onClick={() => this.select(choice.key)}
        >
          <img
            className={'image-grid__image'}
            src={choice.bild}
            alt={choice.text}
          />
        </button>
      </li>
    ))

    return (
      <QuestionScreen
        frage={frage}
        questionType="image-grid"
        isSubmitting={isSubmitting}
      >
        <ul className="image-grid">{buttons}</ul>
        <footer
          className={`footer footer--fixed ${
            selectedAnswer ? '' : 'footer--hidden'
          }`}
        >
          <a className="icon-button" onClick={() => this.submit()}>
            <CheckIcon />
          </a>
        </footer>
      </QuestionScreen>
    )
  }
}
