import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.714 0 0 6.714 0 15s6.714 15 15 15 15-6.714 15-15S23.286 0 15 0zm0 27.097C8.317 27.097 2.903 21.683 2.903 15S8.317 2.903 15 2.903 27.097 8.317 27.097 15 21.683 27.097 15 27.097zm6.157-15.86L17.395 15l3.762 3.762a.726.726 0 0 1 0 1.028l-1.367 1.367a.726.726 0 0 1-1.028 0L15 17.395l-3.762 3.762a.726.726 0 0 1-1.028 0L8.843 19.79a.726.726 0 0 1 0-1.028L12.605 15l-3.762-3.762a.726.726 0 0 1 0-1.028l1.367-1.367a.726.726 0 0 1 1.028 0L15 12.605l3.762-3.762a.726.726 0 0 1 1.028 0l1.367 1.367a.726.726 0 0 1 0 1.028z"
        fill="#FFF"
        fillRule="nonzero"
        opacity=".9"
      />
    </svg>
  )
}
