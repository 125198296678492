import React from 'react'
import {connect} from 'react-redux'

import {Screen} from '../ui'

class Homestory extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Screen screenClass={`screen--blur`}>
        <div className="question">
          <p className="question__text text--bold">{this.props.frage.text}</p>
          <div className="question__content">
            <a
              href={`http://api.ausstellung-mislaebe.ch/auswertung/start/${
                this.props.session.uuid
              }`}
              className="button"
              target="_blank"
            >
              Homestory
            </a>
          </div>
        </div>
      </Screen>
    )
  }
}

export default connect(state => ({session: state.session}))(Homestory)
