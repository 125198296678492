import React from 'react'

import {connect} from 'react-redux'

import Link from 'redux-first-router-link'
import PlayIcon from '../svg/play.js'

import {Screen} from './ui'

export class Start extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      acceptedTerms: false,
    }
  }

  onCheckboxChange = () => {
    this.setState({
      acceptedTerms: this.checkbox.checked,
    })
  }

  render() {
    const {questionListLoaded, sessionCreated} = this.props
    const {acceptedTerms} = this.state
    const loaded = questionListLoaded && sessionCreated

    const terms = loaded ? (
      <div className="terms">
        <input
          className="terms__checkbox"
          type="checkbox"
          id="terms-checkbox"
          ref={ref => (this.checkbox = ref)}
          onChange={this.onCheckboxChange}
        />
        <label className="terms__label" htmlFor="terms-checkbox">
          Ich habe die{' '}
          <a href="/agb.html" target="_blank">
            Nutzungs- und Datenschutzhinweise
          </a>{' '}
          gelesen und bin damit einverstanden.
        </label>
      </div>
    ) : (
      <div className="loading-indicator" />
    )

    return (
      <Screen screenClass={`screen--start`} screenTitle="Start">
        <h1 className="main-title">
          <span className="main-title__sub">Willkommen bei</span>
          <strong>#misläbe</strong>
        </h1>
        {terms}

        <Link
          className={`icon-button ${
            acceptedTerms && loaded ? '' : 'icon-button--hide'
          }`}
          to={{type: 'SHAKE_TEST'}}
        >
          <PlayIcon />
        </Link>
      </Screen>
    )
  }
}

export default connect(state => ({
  questionListLoaded: state.questionList.length,
  sessionCreated: state.session.session_id,
}))(Start)
