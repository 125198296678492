import React from 'react'

import {connect} from 'react-redux'

import {submitAnswer, silentlySendAnswer} from './actions'
import {currentQuestion} from './selectors'

import {QuestionErrorScreen} from './question/questionUi'
import SingleChoiceQuestion from './question/singleChoice'
import ImageGrid from './question/imageGrid'
import {SliderQuestion, AsyncSliderQuestion} from './question/slider'
import TextInputQuestion from './question/textInput'
import PhotoUploadQuestion from './question/photoUpload'
import SwiperQuestion from './question/swiper'
import TextSlide from './question/textSlide'
import AR from './question/ar'
import Homestory from './question/homestory'
import FourOFour from './question/404'

const TYPES = {
  F0: TextSlide,
  F03: SingleChoiceQuestion,
  F04: SliderQuestion,
  F05: ImageGrid,
  F06: AR,
  F07: SwiperQuestion,
  F09: PhotoUploadQuestion,
  F10: TextInputQuestion,
  F11: AsyncSliderQuestion, // TODO: implement async updating slider
  FHS: Homestory,
  FALLBACK: FourOFour,
}

const setAnswer = (dispatch, details, session) => answer => {
  dispatch(
    submitAnswer(details, {
      frage_key: details.key,
      session_id: session.session_id,
      werte: Array.isArray(answer) ? answer.map(a => [a]) : [[answer]],
    })
  )
}

const setAnswerSilently = (details, session) => answer => {
  silentlySendAnswer({
    frage_key: details.key,
    session_id: session.session_id,
    werte: Array.isArray(answer) ? answer.map(a => [a]) : [[answer]],
  })
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  // TODO: Figure out how to use "static getDerivedStateFromError()" --> https://reactjs.org/docs/error-boundaries.html
  componentDidCatch(_error, _info) {
    this.setState({hasError: true})
  }

  render() {
    const {dispatch, currentQuestion, session} = this.props

    if (this.state.hasError) {
      return <QuestionErrorScreen />
    }

    if (currentQuestion && TYPES[currentQuestion.feature_name]) {
      return React.createElement(TYPES[currentQuestion.feature_name], {
        setAnswer: setAnswer(dispatch, currentQuestion, session),
        setAnswerSilently: setAnswerSilently(currentQuestion, session),
        ...currentQuestion,
      })
    }

    if (currentQuestion) {
      // dispatch(
      //   messageAction(`Unknown question type ${currentQuestion.feature_name}`)
      // )
      // dispatch({type: 'POSITION_LIST'})

      return React.createElement(TYPES['FALLBACK'], {
        frage: {text: 'Diese Frage konnte nicht gefunden werden.'},
      })
    } else {
      // Should not happen.
      // window.localStorage.removeItem('redux')
      // window.location.href = '/'
      return React.createElement(TYPES['FALLBACK'], {
        frage: {text: 'Diese Frage konnte nicht gefunden werden.'},
      })
    }
  }
}

export default connect(state => ({
  currentQuestion: currentQuestion(state),
  session: state.session,
}))(ErrorBoundary)
