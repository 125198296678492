import React from 'react'

import {fetchInitialData} from './actions'

import PositionList from './positionList'
import PositionDetails from './positionDetails'
import Start from './start'
import Shake from './shake'
import {Restart} from './restart'

const THINGS = {
  START: {
    path: '/',
    thunk: fetchInitialData,
    view: Start,
  },
  SHAKE_TEST: {
    path: '/shake',
    view: Shake,
  },
  POSITION_LIST: {
    path: '/position',
    view: PositionList,
  },
  POSITION_DETAILS: {
    path: '/position/:id',
    view: PositionDetails,
  },
  QUESTION_DETAILS: {
    path: '/position/:positionIndex/:questionIndex',
    view: PositionDetails,
  },
  RESTART: {
    path: '/restart',
    view: Restart,
  },
}

export const views = {}
export const routes = {}

Object.keys(THINGS).map(key => {
  views[key] = THINGS[key].view
  const {view: _, ...route} = THINGS[key]
  routes[key] = route
})

window.console.log(views, routes)
