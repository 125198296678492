function questionList(state = [], action) {
  switch (action.type) {
    case 'QUESTION_LIST_LOADED':
      return action.questionList
    default:
      return state
  }
}

function completedQuestions(state = [], action) {
  switch (action.type) {
    case 'ANSWER_SUBMISSION_SUCCEEDED':
      return state.indexOf(action.question.key) === -1
        ? [action.question.key, ...state]
        : state
    default:
      return state
  }
}

function completedPositions(state = [], action) {
  switch (action.type) {
    case 'COMPLETE_POSITION':
      return state.indexOf(action.position.postenId) === -1
        ? [action.position.postenId, ...state]
        : state
  }
  return state
}

function currentPositionIndex(state = 0, action) {
  switch (action.type) {
    case 'POSITION_DETAILS':
      return action.payload.id
    case 'QUESTION_DETAILS':
      return action.payload.positionIndex
    default:
      return state
  }
}

function currentQuestionIndex(state = 0, action) {
  switch (action.type) {
    case 'POSITION_DETAILS':
      return 0
    case 'QUESTION_DETAILS':
      return action.payload.questionIndex
    default:
      return state
  }
}

function session(state = {}, action) {
  switch (action.type) {
    case 'SESSION_CREATED':
      return action.session
    default:
      return state
  }
}

// default is true because the customer doesn't want the tutorial at the moment
function completedSwipeTutorial(state = true, action) {
  switch (action.type) {
    case 'SWIPE_TUTORIAL_COMPLETE':
      return true
    default:
      return state
  }
}

function _createmessage(msg) {
  return {
    id: Math.random(),
    message: msg,
    tags: '',
    level_tag: 'error',
    meta: {},
  }
}

function messages(state = [], action) {
  // We look at all actions, and pluck out messages...
  if (action.__messages__ && action.__messages__.length) {
    let messages = [...state],
      messageIds = messages.map(m => m.id)

    for (let m of action.__messages__) {
      if (messageIds.indexOf(m.id) === -1) {
        messages.push(m)
        messageIds.push(m.id)
      }
    }
    return messages

    // Nicer, but misses deduplication of messages:
    // return [...state, ...action.__messages__]
  }
  switch (action.type) {
    case 'INIT':
      return []
    case 'DISMISS_MESSAGE':
      return state.filter(message => message.id !== action.messageId)
    default:
      return state
  }
}

export default {
  questionList,
  completedQuestions,
  completedPositions,
  currentPositionIndex,
  currentQuestionIndex,
  session,
  completedSwipeTutorial,
  messages,
}
