import React from 'react'

import {QuestionScreen} from './questionUi'
import CheckIcon from '../../svg/check.js'

// Creates a function which keeps its argument in the range [low, up]
const boundedValue = (low, up) => num => Math.max(low, Math.min(up, num))

// returns the offset of an element to the document.
function findElementPos(obj) {
  let curleft = 0
  let curtop = 0

  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft
      curtop += obj.offsetTop
      obj = obj.offsetParent
    } while (obj)
  }

  return {
    left: curleft,
    top: curtop,
  }
}

// returns the position of the mouse/touch event relative to an element
function mousePositionElement(coords, element) {
  var targetPos = findElementPos(element)
  var posx = coords.x - targetPos.left
  var posy = coords.y - targetPos.top

  return {
    x: posx,
    y: posy,
  }
}

export class SliderQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      answer: null,
      selectorPosition: null,
      isMoving: false,
      isInit: true,
      isSubmitting: false,
    }

    this.scroll = null
    this.listHeight = 0
  }

  componentDidMount() {
    this.sliderEl.addEventListener('touchstart', this._begin)
    this.sliderEl.addEventListener('touchmove', this._move)
    this.sliderEl.addEventListener('touchend', this._end)

    this.sliderEl.addEventListener('mousedown', this._begin)
    this.sliderEl.addEventListener('mousemove', this._move)
    this.sliderEl.addEventListener('mouseup', this._end)

    this.submitEl.addEventListener('mousedown', this._onSubmitButton)
    this.submitEl.addEventListener('touchstart', this._onSubmitButton)
  }

  componentWillUnmount() {
    this.sliderEl.removeEventListener('touchstart', this._begin)
    this.sliderEl.removeEventListener('touchmove', this._move)
    this.sliderEl.removeEventListener('touchend', this._end)

    this.sliderEl.removeEventListener('mousedown', this._begin)
    this.sliderEl.removeEventListener('mousemove', this._move)
    this.sliderEl.removeEventListener('mouseup', this._end)

    this.submitEl.removeEventListener('mousedown', this._onSubmitButton)
    this.submitEl.removeEventListener('touchstart', this._onSubmitButton)
  }

  getSliderHeight = () => this.sliderEl.offsetHeight

  _coords(event) {
    if (event.changedTouches) {
      return {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      }
    } else {
      return {
        x: event.clientX,
        y: event.clientY,
        screenX: event.screenX,
        screenY: event.screenY,
      }
    }
  }

  _begin = event => {
    this.scroll = event
    event.preventDefault()
    event.stopPropagation()
    this.setState({isMoving: true})
  }

  _move = event => {
    if (!this.scroll) return

    event.preventDefault()
    event.stopPropagation()

    const scrollPosY = mousePositionElement(this._coords(event), this.sliderEl)
      .y
    this.onChange(scrollPosY)
  }

  _end = event => {
    this.scroll = null
    event.preventDefault()
    event.stopPropagation()
    this.setState({isMoving: false})
  }

  _onSubmitButton = event => {
    event.stopPropagation()
    event.preventDefault()

    if (!this.state.isSubmitting) {
      this.props.setAnswer(this.getAnswerKey(this.state.answer))
      this.setState({isSubmitting: true})
    }
  }

  onChange(scrollPosition) {
    let selectorPosition = this.boundedSelectorPosition(scrollPosition)

    let answer = Math.floor(
      selectorPosition / (this.getSliderHeight() / this.props.antworten.length)
    )

    answer = this.boundedAnswer(answer)
    const prevAnswer = this.state.answer

    // Send updates silently to the server
    if (this.props.sendOnChange && prevAnswer != answer) {
      clearTimeout(this.updateTimeout)

      this.updateTimeout = setTimeout(() => {
        this.props.setAnswerSilently(this.getAnswerKey(answer))
      }, 300)
    }

    this.setState({
      answer: answer,
      selectorPosition: selectorPosition,
      isInit: false,
    })
  }

  getAnswerKey = i => this.props.antworten[i].key

  boundedAnswer = answer =>
    boundedValue(0, this.props.antworten.length - 1)(answer)

  boundedSelectorPosition = position =>
    boundedValue(0, this.getSliderHeight())(position)

  render = () => {
    const {frage, antworten} = this.props
    const {selectorPosition, isMoving, isInit, isSubmitting} = this.state

    const answers = antworten.map((choice, i) => (
      <li
        key={i}
        className={`slider__list-item ${
          this.state.answer == i ? 'slider__list-item--active' : ''
        }`}
      >
        <span
          className={`slider__answer-text ${
            choice.hint ? 'slider__answer-text--hint' : ''
          }`}
        >
          {choice.text}
          {choice.hint ? (
            <span
              className="slider__answer-hint"
              dangerouslySetInnerHTML={{__html: choice.hint}}
            />
          ) : null}
        </span>
      </li>
    ))

    return (
      <QuestionScreen
        frage={frage}
        isSubmitting={isSubmitting}
        questionType="slider"
      >
        <div className="slider" ref={slider => (this.sliderEl = slider)}>
          <ul className="slider__list">{answers}</ul>
          <div
            style={{top: selectorPosition != null ? selectorPosition : '50%'}}
            className={`slider__selector ${isMoving ? 'active' : ''} ${
              isInit ? 'init' : ''
            }`}
          >
            <div className="slider__thumb" />
            <button
              type="button"
              className={`icon-button slider__submit ${
                !isInit && !isMoving ? 'show' : ''
              }`}
              ref={button => (this.submitEl = button)}
            >
              <CheckIcon />
            </button>
          </div>
        </div>
      </QuestionScreen>
    )
  }
}

export const AsyncSliderQuestion = props => {
  return <SliderQuestion sendOnChange={true} {...props} />
}
