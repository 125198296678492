import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.379 20.19c0 11.15-9.04 20.189-20.19 20.189C9.04 40.379 0 31.339 0 20.189 0 9.04 9.04 0 20.19 0c11.15 0 20.189 9.04 20.189 20.19zM17.854 30.88l14.98-14.98a1.303 1.303 0 0 0 0-1.842l-1.843-1.842a1.303 1.303 0 0 0-1.842 0L16.933 24.432 11.23 18.73a1.303 1.303 0 0 0-1.843 0L7.545 20.57a1.303 1.303 0 0 0 0 1.842l8.467 8.466a1.303 1.303 0 0 0 1.842 0z"
        fill="#CAFF62"
        fillRule="nonzero"
        opacity=".898"
      />
    </svg>
  )
}
