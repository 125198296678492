import React from 'react'
import {connect} from 'react-redux'

import DetectRTC from 'detectrtc'

import {nextQuestion} from '../actions'
import {nextQuestionUrl} from '../selectors'

import ArrowIcon from '../../svg/arrow-right.js'
import {Screen} from '../ui'
import TextSlide from './textSlide.js'
import {QuestionErrorScreen} from './questionUi'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

class ArRenderer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      webRTCDetected: false,
      webRTCIsSupported: false,
      hasError: false,
      hasPermission: true,
    }

    const arData = {
      antworten: this.props.antworten,
      nextQuestionUrl: this.props.nextQuestionUrl,
      activeIFrame: !iOS,
    }

    window.localStorage.setItem('arData', JSON.stringify(arData))
  }

  componentDidMount() {
    DetectRTC.load(() => {
      this.setState({
        webRTCDetected: true,
        webRTCIsSupported: DetectRTC.isWebRTCSupported && DetectRTC.hasWebcam,
      })
    })

    window.addEventListener('message', this.messageHandler)
  }

  componentDidUpdate() {
    const {webRTCIsSupported, webRTCDetected} = this.state
    // open the ar container in a new page on iOS devices
    if (iOS && webRTCDetected && webRTCIsSupported) {
      // set a delay so the navigating with the back button is still possible.
      setTimeout(() => {
        // pass next URL in case of an localStorage error
        window.location.href = `/ar.html?next=${encodeURIComponent(
          this.props.nextQuestionUrl
        )}`
      }, 1200)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler)
  }

  messageHandler = e => {
    if (e.origin === document.location.origin && e.data.type === 'arIFrame') {
      const newState = {}
      if (e.data.hasError) {
        newState.hasError = true
      }

      if (!e.data.hasPermission) {
        newState.hasPermission = false
      }

      this.setState(newState)
    }
  }

  render() {
    const {webRTCIsSupported, webRTCDetected, hasError, hasPermission} = this.state

    if (hasError) {
      return (
        <QuestionErrorScreen />
      )
    }

    if (!hasPermission) {
      return (
        <QuestionErrorScreen text='Dieses Feature benötigt den Zugriff auf die Kamera. Lade bitte die Seite erneut und erlaube den Zugriff.' />
      )
    }

    const loading = (
      <Screen screenClass="screen--ar">
        <div className="loading-indicator" />
      </Screen>
    )

    let screen

    if (!webRTCDetected) return loading

    if (webRTCIsSupported) {
      screen = iOS ? (
        loading
      ) : (
        <Screen screenClass="screen--ar screen--iframe">
          <div className="screen__skip-header">
            Probleme bei der Anzeige?
            <a href="#" onClick={e => {e.preventDefault(); this.props.onClick()}}>
              Überspringen
            </a>
          </div>
          <button
            className={`icon-button icon-button--ar`}
            onClick={() => this.props.onClick()}
          >
            <ArrowIcon />
          </button>
          {/* load the ar container in a an iFrame on non-iOS devices*/}
          <iframe
            className="ar-iframe"
            src="/ar.html"
            ref={ref => (this.iframe = ref)}
          />
        </Screen>
      )
    } else {
      // TODO: Update Final Fallback Text
      screen = (
        <TextSlide
          frage={{
            text: 'Dein Browser unterstützt dieses Feature nicht.',
          }}
        />
      )
    }
    return screen
  }
}

export default connect(
  state => ({
    nextQuestionUrl: nextQuestionUrl(state),
  }),
  dispatch => ({
    onClick: () => {
      dispatch(nextQuestion())
    },
  })
)(ArRenderer)
