import './main.scss'

import 'babel-polyfill'
// import 'blissfuljs'
// const {$, $$} = window // eslint-disable-line

import {renderInElement} from './js/app'

document.addEventListener('DOMContentLoaded', () => {
  // const loader = document.getElementById('loader')
  // loader && renderInElement(loader)

  let loader = document.createElement('div')
  document.body.appendChild(loader)
  renderInElement(loader)
})
