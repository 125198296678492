import React from 'react'

import {connect} from 'react-redux'

import {Screen} from '../ui'
import {shakeHelper} from '../utils'
import {nextQuestion} from '../actions'

class QuestionScreenComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.onShake) {
      shakeHelper.start(this.props.onShake)
    }
  }

  componentWillUnmount() {
    if (this.props.onShake) {
      shakeHelper.stop()
    }
  }

  render() {
    const {frage, children, questionType, isSubmitting} = this.props

    return (
      <Screen
        screenClass={`screen--question ${
          questionType ? 'screen--' + questionType : ''
        }`}
      >
        <div className="question">
          <p
            className="question__text text--bold"
            dangerouslySetInnerHTML={{__html: frage.text}}
          />
          {/* TODO: Andere Werte v. frageart? */}
          <div className="question__content">{children}</div>
        </div>

        {isSubmitting ? (
          <div className="loading-overlay">
            <p>Senden ...</p>
            <div className="loading-overlay__indicator" />
          </div>
        ) : null}
      </Screen>
    )
  }
}

export const QuestionScreen = connect(null, dispatch => ({
  onShake: () => dispatch(nextQuestion()),
}))(QuestionScreenComponent)


const QuestionErrorScreenComponent = ({skip, text}) => {
  return (
    <Screen screenClass={`screen--question screen--error`}>
      <p>{text ? text : 'Es ist ein Fehler aufgetreten. Lade die Seite neu und versuche es noch einmal oder gehe weiter zur nächsten Frage'}</p>
      <a href="#" onClick={e => {e.preventDefault(); skip()}}>
        Überspringen
      </a>
    </Screen>
  )
}

export const QuestionErrorScreen = connect(null, dispatch => ({
  skip: () => {
    dispatch(nextQuestion())
  },
}))(QuestionErrorScreenComponent)
