import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 2.903c6.685 0 12.097 5.41 12.097 12.097 0 6.685-5.41 12.097-12.097 12.097-6.685 0-12.097-5.41-12.097-12.097C2.903 8.315 8.313 2.903 15 2.903zm8.48 7.88l-1.363-1.375a.726.726 0 0 0-1.026-.004l-8.55 8.48-3.616-3.645a.726.726 0 0 0-1.027-.004l-1.374 1.363a.726.726 0 0 0-.004 1.026l5.49 5.536a.726.726 0 0 0 1.027.004l10.439-10.355a.726.726 0 0 0 .004-1.027z"
        fill="#FFF"
        fillRule="nonzero"
        opacity=".9"
      />
    </svg>
  )
}
