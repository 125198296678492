import React from 'react'
// import {connect} from 'react-redux'
import PropTypes from 'prop-types'
// import Link from 'redux-first-router-link'

import throttle from 'lodash.throttle'
import {restartApp} from './restart'

export class Screen extends React.Component {
  constructor(props) {
    super(props)
    this.resizeHandler = throttle(this._resizeHandler, 100).bind(this)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    // this.screenTitle = this.screenEl.querySelector('.screen__title')
    // this.screenContents = this.screenEl.querySelector('.screen__contents')

    window.addEventListener('resize', this.resizeHandler)
    setTimeout(this.resizeHandler)
    window.addEventListener('load', () => {
      this.resizeHandler()
    })

    // TODO: overwrite body styles, remove resize handler
    // document.body.style = ""
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  _resizeHandler = () => {
    // the reason for doing this by JS and not just using
    // min-height: 100vh to screen is that iOS has a bug
    // when it comes to vh units and also
    // we are setting the min height of the screenContents
    // and not the screen itself because IE has a flexbug
    // with min-height
    // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items

    // prettier-ignore
    let fullHeight =
      window.innerHeight
      // the paddingTop changes between small and medium
      // - parseInt(window.getComputedStyle(document.body).paddingTop)
      // - this.screenTitle.offsetHeight - 1
      // take own margin into account
      // - parseInt(window.getComputedStyle(this.screenEl).marginBottom)
      + 'px'

    // do not allow screen to be higher
    if (this.props.allowScroll) {
      this.screenEl.style.minHeight = fullHeight
    } else {
      this.screenEl.style.height = fullHeight
    }
  }

  render = () => (
    <div
      className={`screen ${this.props.screenClass}`}
      ref={screenEl => (this.screenEl = screenEl)}
    >
      {/*<div className="screen__icons">
         <a href="#" title="Restart" onClick={restartApp}>
          ↻
        </a>
      </div>*/}
      <div className="screen__background" />
      <div className="screen__contents">{this.props.children}</div>
    </div>
  )
}

Screen.propTypes = {
  screenClass: PropTypes.string,
  overlays: PropTypes.array,
  children: PropTypes.node,
}
