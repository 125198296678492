import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import ReduxThunk from 'redux-thunk'
import persistState from 'redux-localstorage'

import createHistory from 'history/createBrowserHistory'
import {connectRoutes} from 'redux-first-router'

import reducers from './reducers'
import {routes} from './routes'

const history = createHistory(),
  router = connectRoutes(history, routes),
  middleware = [ReduxThunk, router.middleware]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(require('redux-logger').default)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  combineReducers({
    ...reducers,
    location: router.reducer,
  }),
  undefined, // window.__initial_context,
  composeEnhancers(
    persistState(),
    router.enhancer,
    applyMiddleware(...middleware)
  )
)

store.dispatch({type: 'INIT'})

export default store
