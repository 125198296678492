import React from 'react'

import {connect} from 'react-redux'

import {QuestionScreen} from './questionUi'
import ArrowIcon from '../../svg/arrow-right.js'

import {nextQuestion} from '../actions'

const TextSlide = ({onClick, frage}) => {
  return (
    <QuestionScreen frage={frage}>
      <button className={`icon-button`} onClick={() => onClick()}>
        <ArrowIcon />
      </button>
    </QuestionScreen>
  )
}

export default connect(null, dispatch => ({
  onClick: () => {
    dispatch(nextQuestion())
  },
}))(TextSlide)
