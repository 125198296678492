import React from 'react'
import {connect} from 'react-redux'
import Link from 'redux-first-router-link'

import {dismissMessageAction} from './actions'

class Message extends React.Component {
  render = () => {
    const {message} = this.props
    const button = message.meta.url ? (
      <Link to={message.meta.url} onClick={this.onClick} className="button">
        {message.meta.buttonTitle || '✖'}
      </Link>
    ) : message.meta.reload ? (
      <button className="button" onClick={() => window.location.reload()}>
        {message.meta.buttonTitle || 'Seite neu laden'}
      </button>
    ) : (
      <button className="button" onClick={this.onClick}>
        {message.meta.buttonTitle || '✖'}
      </button>
    )

    return (
      <div className={`messages__message ${message.tags}`}>
        <p>{message.message}</p>
        {button}
      </div>
    )
  }

  onClick = () => {
    let {dispatch, message} = this.props
    dispatch(dismissMessageAction(message.id))
  }
}

const Messages = connect(state => ({
  messages: state.messages,
}))(({messages, dispatch}) => {
  const items = messages.map(message => (
    <Message key={message.id} message={message} dispatch={dispatch} />
  ))

  return items.length ? <div className="messages">{items}</div> : null
})

export default Messages
